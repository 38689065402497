import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Store from "./components/Store";
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/css/index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store} >
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('app')
);
reportWebVitals();
