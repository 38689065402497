import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";

import { setUserData, clearAllData } from '../../actions';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { useNavigate } from "react-router";

const Layout = ({ children, user, setUserData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setUserData(JSON.parse(userData));
            navigate('/dashboard');
        }
    }, []);

    const navItems = [
        {
            id: "dashboard",
            title: "Dashboard",
            action: "/dashboard"
        },
        {
            id: "privacy",
            title: "Privacy",
            action: "/privacy",
        },
        {
            id: "terms",
            title: "T&C",
            action: "/terms",
        }
    ];

    const logoutUser = () => {
        localStorage.clear();
        dispatch(clearAllData());
        navigate(`/`);
    }

    return (
        <div className="App">
            <div className="App__background"></div>
            <div className="App__wrapper">
                {
                    user && (
                        <Sidebar items={navItems} user={get(user, 'username', '')} onLogout={() => logoutUser()} />
                    )
                }
                <div className="App__content">
                    {children}
                    <Footer copyright={`©${(new Date()).getFullYear()} AEGIS London`} />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.credentials
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (data) => { dispatch(setUserData(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);