import axios from 'axios';
import { get } from "lodash";

var api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
api.interceptors.request.use((config) => {
    let conf = {
        ...config,
        headers: {
            ...config.headers,
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    }
    return conf;
})

api.interceptors.response.use(response => {
    let data = get(response, "data");
    if (get(response, "data.status", 200) === 401) {
        //Remove all local storage
        localStorage.clear();
        //Redirect to Home
        window.location = "/";
    }
    return {
        ...response,
        data
    }
}, (error) => {
    return Promise.reject(error)
})

export default api;