import api from "./api";

export function fetchPolicy(page, perPage, keyword) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_LIST",
      payload: api.get("/policy", {
        params: {
          from: page,
          limit: perPage,
          keyword: keyword
        }
      }),
      meta: { attribute: "policy", from: page }
    });
  };
}

export function fetchPolicyItem(id) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.get("/policy/" + id),
      meta: { attribute: "policy" }
    });
  };
}

export function cancelPolicy(data) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.post("/contact/cancel-policy", data),
      meta: { attribute: "cancel_policy" }
    });
  };
}

export function extendPolicy(data) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.post("/contact/extend-policy", data),
      meta: { attribute: "extend_policy" }
    });
  };
}

export function surplusPolicy(data) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.post("/contact/surplus-lines", data),
      meta: { attribute: "surplus" }
    });
  };
}

export function insuredName(data, type) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.post("/contact/insured-name", data),
      meta: { attribute: type }
    });
  };
}

export function updateLocation(data) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.post("/contact/location-values", data),
      meta: { attribute: 'location_values' }
    });
  };
}

export function addLocation(data) {
  return (dispatch) => {
    dispatch({
      type: "LOAD_ITEM",
      payload: api.post("/contact/location", data),
      meta: { attribute: 'addlocation' }
    });
  };
}

export function submitForm(attribute, data) {
  let url = "/";
  if (attribute === "reset") {
    return (dispatch) => {
      dispatch({
        type: "SUBMIT",
        payload: api.patch(url + attribute, data),
        meta: { attribute, formData: data },
      });
    };
  }
  return (dispatch) => {
    dispatch({
      type: "SUBMIT",
      payload: api.post(url + attribute, data),
      meta: { attribute, formData: data },
    });
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      payload: api.get('/logout'),
    });
  };
}

export function clearAllData() {
  return dispatch => {
    dispatch({
      type: "USER_LOGOUT"
    })
  }
}

export function setUserData(data) {
  return (dispatch) => {
    dispatch({
      type: "USER_DATA",
      payload: { data }
    });
  };
}