import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from "./pages/Layout";

const Dashboard = lazy(() => import("./pages/Dashboard/index"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Policy = lazy(() => import("./pages/Policy"));
const Forgot = lazy(() => import("./pages/Login/Popups/Forgot"));
const ForgotSuccess = lazy(() => import("./pages/Login/Popups/ForgotSuccess"));
const Reset = lazy(() => import("./pages/Login/Popups/Reset"));
const ResetSuccess = lazy(() => import("./pages/Login/Popups/ResetSuccess"));

const Endorse = lazy(() => import("./pages/Policy/Popups/Endorse"));
const Locations = lazy(() => import("./pages/Policy/Popups/Locations"));
const Cancel = lazy(() => import("./pages/Policy/Popups/Cancel"));
const Extend = lazy(() => import("./pages/Policy/Popups/Extend"));
const Surplus = lazy(() => import("./pages/Policy/Popups/Surplus"));
const MortgagePayees = lazy(() => import("./pages/Policy/Popups/MortgagePayees"));
const NamedInsured = lazy(() => import("./pages/Policy/Popups/NamedInsured"));
const AmendOriginalNamedInsured = lazy(() => import("./pages/Policy/Popups/AmendOriginalNamedInsured"));
const AmendLocationValues = lazy(() => import("./pages/Policy/Popups/AmendLocationValues"));
const Login = lazy(() => import("./pages/Login/index"));
const Privacy = lazy(() => import("./pages/Privacy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/privacy/:section" element={<Privacy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/login" element={<Login />}>
              <Route path="forgot" element={<Forgot />} />
              <Route path="forgot/success" element={<ForgotSuccess />} />
              <Route path="reset-password/:hash*" element={<Reset />} />
              <Route path="reset/success" element={<ResetSuccess />} />
            </Route>
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="policy/:id" element={<PrivateRoute><Policy /></PrivateRoute>}>
              <Route path="endorse" element={<Endorse />} />
              <Route path="locations" element={<Locations />} />
              <Route path="cancel" element={<Cancel />} />
              <Route path="extend" element={<Extend />} />
              <Route path="surplus-lines" element={<Surplus />} />
              <Route path="mortgagee-and-loss-payees" element={<MortgagePayees />} />
              <Route path="add-named-insured" element={<NamedInsured />} />
              <Route path="amend-original-named-insured" element={<AmendOriginalNamedInsured />} />
              <Route path="amend-location-values" element={<AmendLocationValues />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <ToastContainer draggable={false} position={toast.POSITION.TOP_RIGHT} transition={Slide} autoClose={300} hideProgressBar={true} closeOnClick={true} rtl={false} pauseOnVisibilityChange={false} newestOnTop={true} />
      </Layout>
    </BrowserRouter>
  );
}

function PrivateRoute({ children, redirectTo = "/" }) {
  let isAuthenticated = localStorage.getItem('token') ? true : false;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default App;