let defaultState = {
	data: {},
	status: false,
	fetching: false,
	serverError: [],
	formData: {}
}

export default function submitReducer(state = {
	login: defaultState,
	reset: defaultState,
	forgot: defaultState
}, action) {
	switch (action.type) {
		case "SUBMIT_PENDING": {
			const { meta: { attribute, formData } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					fetching: true,
					formData
				}
			}
		}
		case "SUBMIT_REJECTED": {
			const { meta: { attribute } } = action;
			return {
				...state,
				[attribute]: {
					...defaultState,
					serverError: {
						"error": "Error in fetching content"
					}
				}
			}
		}
		case "SUBMIT_FULFILLED": {
			const { meta: { attribute, formData } } = action;
			const { data: { data, status } } = action.payload;

			let retState = state;

			return {
				...retState,
				[attribute]: {
					...defaultState,
					data,
					status,
					serverError: status !== 200 ? data : [],
					formData
				}
			}
		}
	}
	return state;
}