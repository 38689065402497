import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

const SidebarItem = ({ id, title, action, active = false, subItems = [] }) => {
  const isLink = typeof action === "string";
  const Tag = isLink ? Link : "button";
  const props = {
    [isLink ? "to" : "onClick"]: action,
    "activeclassname": "Sidebar__navigation__link--active",
  };

  return (
    <Tag
      {...props}
      className={classnames(
        "Sidebar__navigation__link",
        `Sidebar__navigation__link--${id}`, {
        "Sidebar__navigation__link--active": active,
      }
      )}
    >
      <span className="Sidebar__navigation__link__label">
        {title}
      </span>
      <nav className="Sidebar__navigation__link__sub-menu">
        {(subItems || []).map(item => <SidebarItem key={item.id} {...item} />)}
      </nav>
    </Tag>
  );
};

export default SidebarItem;
