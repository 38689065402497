import React from "react";
import SidebarItem from "./SidebarItem";

const Sidebar = ({ user = "", items = [], isLRM = false, onLogout }) => {
  return (
    <aside className="Sidebar">
      <p className="Sidebar__username">
        {user}
        <button
          className="Sidebar__logout"
          onClick={onLogout}
          type="button">
          Logout
        </button>
      </p>
      <nav className="Sidebar__navigation">
        {(items || []).map(item =>
          <SidebarItem
            isLRM={isLRM}
            key={item.id}
            {...item}
          />
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;
