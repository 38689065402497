import { combineReducers } from "redux";
import { reducer as forms } from 'redux-form';
import credentialsReducer from './credentialsReducer';
import listReducer from './listReducer';
import submitReducer from './submitReducer';

const appReducer = combineReducers({
    form: forms,
    credentials: credentialsReducer,
    list: listReducer,
    submit: submitReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;