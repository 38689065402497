import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ copyright = "", links = [{ "name": "Terms of Use", "to": "/terms", }, { "name": "Privacy Policy", "to": "/privacy", }] }) => {
    return (
        <div className="Footer">
            <div className="Footer__wrapper">
                <span className="Footer__copyright">{copyright}</span>
                {links.map(({ name = "", to = "" }, index) =>
                    <Link
                        className="Footer__link"
                        key={index}
                        to={to}>
                        {name}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Footer;