import { get } from "lodash";

let defaultState = {
	fetching: false,
	status: false,
	data: [],
	order: {
		field: "id",
		sort_order: "desc"
	},
	original: [],
	list: [],
	item: {},
	serverError: [],
	end: true,
	total: 0,
	action: '',
	parent: {}
}

export default function listReducer(state = {
	policy: defaultState,
	cancel_policy: defaultState,
	locations: defaultState,
	extend_policy: defaultState,
	surplus: defaultState,
	mortgage_payees: defaultState,
	named_insured: defaultState,
	amend: defaultState,
	location_values: defaultState,
	addlocation: defaultState
}, action) {
	switch (action.type) {
		case "FETCH_LIST_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState,
					order: get(state[attribute], 'order', defaultState.order),
					item: get(state[attribute], 'item', {}),
					total: get(state[attribute], 'total', 0),
					list: get(state[attribute], 'list', 0),
					action: action.meta.action,
					fetching: true
				}
			}
		}
		case "FETCH_LIST_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...defaultState
				}
			}
		}
		case "FETCH_LIST_FULFILLED": {
			const { attribute, search, from } = action.meta;
			let end = get(action.payload.data, "data.mastertotal") < 20 ? true : false;
			let total = (from == 0) ? get(action.payload, "data.data.mastertotal") : state[attribute].total
			if (search) {
				end = true;
			}
			return {
				...state,
				[attribute]: {
					...defaultState,
					order: get(state[attribute], 'order', defaultState.order),
					status: get(action.payload, "data.status", 0),
					data: get(action.payload, "data.data", []),
					original: get(action.payload, "data.data.results", []),
					list: get(action.payload, "data.data.results", []),
					total: total,
					end: end,
					item: get(state[attribute], 'item', {})
				}
			}
		}
		case "LOAD_ITEM_PENDING": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: true,
					item: {}
				}
			}
		}
		case "LOAD_ITEM_REJECTED": {
			const { attribute } = action.meta;
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false
				}
			}
		}
		case "LOAD_ITEM_FULFILLED": {
			const { attribute } = action.meta;
			let data = get(action.payload.data, "data", {});
			let status = get(action.payload.data, "status", 0);
			if (status === 200) {
				return {
					...state,
					[attribute]: {
						...state[attribute],
						fetching: false,
						item: data,
						status: status
					}
				}
			}
			return {
				...state,
				[attribute]: {
					...state[attribute],
					fetching: false,
					item: data,
					status: status,
					serverError: data
				}
			}
		}
		default: {
			return state;
		}
	}
}