import { get } from "lodash";

let defaultState = {
	fetching: false,
	status: null,
	formData: {},
	serverError: [],
	user: false,
	message: false,
	token: false,
	userData: false
}

export default function credentialsReducer(state = { ...defaultState }, action) {
	switch (action.type) {
		case "ALERT": {
			return {
				...state,
				message: action.payload
			}
		}
		case "LOGIN_FETCH_PENDING":
		case "USER_PENDING": {
			return { ...state, fetching: true }
		}
		case "LOGIN_FETCH_REJECTED":
		case "USER_REJECTED": {
			return { ...state, fetching: false }
		}
		case "LOGIN_FETCH_FULFILLED": {
			if (get(action.payload.data, "status") !== 200) {
				return {
					...state,
					fetching: false,
					serverError: get(action.payload.data, "data"),
					formData: get(action.payload.data, "data"),
					status: get(action.payload.data, "status"),
				}
			}
			return {
				...state,
				fetching: false,
				formData: get(action.payload.data, "data"),
				status: get(action.payload.data, "status"),
				serverError: []
			}
		}

		case "USER_FULFILLED": {
			let d = action.payload.data;
			if (get(d, "status") === 200) {
				return {
					...state,
					fetching: false,
					status: get(d, "status"),
					user: d.data
				}
			}
		}

		case "USER_DATA": {
			const { data } = action.payload;
			return {
				...state,
				user: { ...data }
			};
		}
		default: {
			return state;
		}
	}
}